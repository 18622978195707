import { useMutation } from "@apollo/client";
import { Box, Button, FormControl, Hidden, Input, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { useForm } from "react-hook-form"
import apiClient, { ApiClient } from "../../lib/apiClient";
import useSession from "../../helpers/useSessionState";
import moment from "moment-timezone";
import { useState } from "react";

interface SiteFormInputs {
    name: string
    timezone: string
    integration: string
    integrationName: string
}

interface SiteCreateProps {
    onComplete?: Function
}
const CreateSite = (props: SiteCreateProps)=>{
    const [state, setState] = useState<{integrationName?: string}>({})
     
    const [sessionState] = useSession()
    const {
        register,
        handleSubmit,
        // formState: { errors },
        getValues,
        setValue
    } = useForm<SiteFormInputs>()
    const [createSite, { loading, error }] = useMutation<ApiClient.GetSites["sites"][0]>(apiClient.mutations.CREATE_SITE);

    const handleCreateSite = (site: any)=>{
        createSite({
            variables: {
                site: {
                    ...site,
                    practiceId: sessionState?.currentPracticeId
                }
            }
        }).then(()=>{
            if(props.onComplete) props.onComplete()
        }).catch((error)=>{
            console.log(error)
        })
    }

    const handleIntegration = (key: string, e: React.ChangeEvent<HTMLInputElement>)=> {
        const integration = JSON.parse(getValues('integration') ?? '{}')
        integration[key] = e.target.value
        setValue('integration', JSON.stringify(integration))
    }
    
    const handleIntegrationChange = (e: SelectChangeEvent)=>{
        setState({
            integrationName: String(e.target.value)
        })
    }
    return (
        <Box
            sx={{p: 2}}
        >
            <form onSubmit={handleSubmit(handleCreateSite)}>
                {/* register your input into the hook by invoking the "register" function */}
                {loading ?(<>
                    Loading...
                </>):(
                    <>
                        {error && error?.message}
                        <FormControl>
                            <Typography component="h4">
                                New Site
                            </Typography>
                        </FormControl>
                        <br />
                        <br />
                        <FormControl>
                            <InputLabel htmlFor="name">Location Name</InputLabel>
                            <Input id="name" defaultValue="" {...register("name")} required={true} />
                        </FormControl>
                        <br />
                        <br />
                        
                        <FormControl>
                            <InputLabel htmlFor="timezone">Timezone</InputLabel>
                            <Select {...register("timezone")}>
                                {moment.tz.names().map((tz)=>(
                                    <MenuItem value={tz}>{tz}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <br />
                        <br />
                            
                        <FormControl>
                            <InputLabel htmlFor="integrationName">Integration</InputLabel>
                            <Select {...register("integrationName")} onChange={handleIntegrationChange}>
                                <MenuItem value={'covetrus'}>Covetrus</MenuItem>
                                <MenuItem value={'datapoint'}>Datapoint</MenuItem>
                            </Select>
                        </FormControl>
                        <br />
                        <br />
                        
                        {state.integrationName == 'covetrus' && (
                            <>    
                                <FormControl>
                                    <InputLabel htmlFor="installation">Installation</InputLabel>
                                    <Input onChange={handleIntegration.bind(null, 'installation')} />
                                </FormControl>
                                <br />
                                <FormControl>
                                    <InputLabel htmlFor="siteId">Site ID</InputLabel>
                                    <Input onChange={handleIntegration.bind(null, 'siteId')} />
                                </FormControl>
                            </>
                        )}
                        {state.integrationName == 'datapoint' && (
                            <>
                                <FormControl>
                                    <InputLabel htmlFor="siteId">Site ID</InputLabel>
                                    <Input onChange={handleIntegration.bind(null, 'siteId')} />
                                </FormControl>
                            </>
                        )}

                        {/* include validation with required or other standard HTML validation rules */}
                        {/* errors will return when field validation fails  */}
                        <br />
                        <br />
                        <Button type="submit">Create</Button>
                    </>
                )}
            </form>
        </Box>
    )
}

export default CreateSite