import { useQuery } from "@apollo/client";
import apiClient, { ApiClient } from "../lib/apiClient";
import useSession from "../helpers/useSessionState";
import { Button, Drawer } from "@mui/material";
import { useState } from "react";
import CreateCompany from "../components/forms/CompanyCreate";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import AddUserToCompany from "../components/forms/AddUserToCompany";

const Companies = ()=>{
    const [state, setState] = useState({
        createCompany: false,
        addUserToCompany: false
    })
    const setDrawer = (key: 'addUserToCompany' | 'createCompany', value: boolean)=>{
        if(key === 'addUserToCompany'){
            setState({
                ...state,
                addUserToCompany: value
            })
        }else if(key === 'createCompany'){
            setState({
                ...state,
                createCompany: value
            })
        }
    }
    
    const navigate = useNavigate()
    const { loading, error, data } = useQuery<ApiClient.GetCompanies>(apiClient.queries.GET_COMPANIES);

    const [sessionState, setSession] = useSession()
 
    
    const handleSelectCompany = (company: ApiClient.GetCompanies["companies"][0])=>{
        setSession({
            ...Object(sessionState),
            currentCompanyId: company.id
        })
        navigate("/")
    }

    return <Layout page="Companies">
        {loading ? (
            <>
                LOADING...
            </>
        ):error?(
            <>{error.message}</>
        ):(
            <>
                {sessionState?.isSuper && (
                    <div>
                        <Button variant="contained" onClick={setDrawer.bind(null, 'createCompany', true)}>Create Company</Button>
                        <Button variant="contained" onClick={setDrawer.bind(null, 'addUserToCompany', true)}>Add User To Company</Button>
                        <Drawer
                            anchor='right'
                            open={state.createCompany}
                            onClose={setDrawer.bind(null, 'createCompany', false)}
                        >
                            <CreateCompany onComplete={setDrawer.bind(null, 'createCompany', false)}/>
                        </Drawer>
                        <Drawer
                            anchor='right'
                            open={state.addUserToCompany}
                            onClose={setDrawer.bind(null, 'addUserToCompany',  false)}
                        >
                            <AddUserToCompany onComplete={setDrawer.bind(null, 'addUserToCompany', false)}/>
                        </Drawer>
                    </div>
                )}
                
                {data?.companies.map((company, i: Number)=>(
                    <div key={i.toString()}>
                        <Link to="#" onClick={handleSelectCompany.bind(null, company)}>
                            {company.name}
                        </Link>
                    </div>
                ))}
            </>
        )}
    </Layout>
}

export default Companies