import { useQuery } from "@apollo/client";
import apiClient, { ApiClient } from "../lib/apiClient";
import useSession from "../helpers/useSessionState";
import { Button, Drawer } from "@mui/material";
import { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import CreatePractice from "../components/forms/PracticeCreate";
import AddUserToPractice from "../components/forms/AddUserToPractice";

const Practices = ()=>{
    const [state, setState] = useState({
        createPractice: false,
        addUserToPractice: false
    })
    
    const setDrawer = (key: 'addUserToPractice' | 'createPractice', value: boolean)=>{
        if(key === 'addUserToPractice'){
            setState({
                ...state,
                addUserToPractice: value
            })
        }else if(key === 'createPractice'){
            setState({
                ...state,
                createPractice: value
            })
        }
    }
    const navigate = useNavigate()
    const { loading, error, data } = useQuery<ApiClient.GetPractices>(apiClient.queries.GET_PRACTICES);

    const [sessionState, setSession] = useSession()
    
    const {data: companyData } = useQuery<ApiClient.GetCompanies>(apiClient.queries.GET_COMPANIES);
    const {data: practiceData } = useQuery<ApiClient.GetPractices>(apiClient.queries.GET_PRACTICES);

    if(!sessionState?.isSuper){ 
        if(sessionState?.currentCompanyId === null && companyData?.companies?.[0]?.id){
            setSession({
                ...sessionState,
                currentCompanyId: companyData.companies[0].id
            })
        }
    }

    const handleSelectPractice = (practice: ApiClient.GetPractices["practices"][0])=>{
        setSession({
            ...Object(sessionState),
            currentPracticeId: practice.id
        })
        navigate("/")
    }

    return <Layout page="Practices">
        {loading ? (
            <>
                LOADING...
            </>
        ):error?(
            <>{error.message}</>
        ):(
            <>
                {sessionState?.isSuper && sessionState?.currentCompanyId != null && (
                    <div>
                        <Button variant="contained" onClick={setDrawer.bind(null, 'createPractice', true)}>Create Practice</Button>
                        <Button variant="contained" onClick={setDrawer.bind(null, 'addUserToPractice', true)}>Add User To Practice</Button>
                        <Drawer
                            anchor='right'
                            open={state.createPractice}
                            onClose={setDrawer.bind(null, 'createPractice', false)}
                        >
                            <CreatePractice onComplete={setDrawer.bind(null, 'createPractice', false)}/>
                        </Drawer>
                        <Drawer
                            anchor='right'
                            open={state.addUserToPractice}
                            onClose={setDrawer.bind(null, 'addUserToPractice',  false)}
                        >
                            <AddUserToPractice onComplete={setDrawer.bind(null, 'addUserToPractice', false)}/>
                        </Drawer>
                    </div>
                )}
                
                {data?.practices?.map((practice, i: Number)=>(
                    <div key={i.toString()}>
                        <Link to="#" onClick={handleSelectPractice.bind(null, practice)}>
                            {practice.name}
                        </Link>
                    </div>
                ))}
            </>
        )}
    </Layout>
}

export default Practices