import { Link } from "react-router-dom"
import useSession from "../helpers/useSessionState"
import Layout from "../components/Layout"

const Home = ()=>{
    const [sessionState] = useSession()
    return (
        <Layout page="Home">
            HOME
        </Layout>
    )
}
export default Home