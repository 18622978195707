import { ApolloClient, InMemoryCache, createHttpLink, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";

loadDevMessages();
loadErrorMessages();

let uri = 'http://localhost:4000/graphql'
if(window.location.hostname !== 'localhost'){
    uri = 'https://datamonitor.vetsuite.io/graphql'
}

const httpLink = createHttpLink({
    uri,
});
  
const authLink = setContext((_, { headers }) => {
// get the authentication token from local storage if it exists
    const sessionString = localStorage.getItem('session')
    let session : {token?: string} = {}
    try{
        if(sessionString){
            session = JSON.parse(sessionString)
        }
    }catch(error){
        console.log(error)
    }

    const token = session.token;
    let optional = {}
    if(token){
        optional = {
            authorization: token ? `Bearer ${token}` : "",
        }
    }

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            ...optional
        }
    }
});
export namespace ApiClient {
    export type GetCalendar = GetAppointments & GetBlockoffs;
    
    export interface GetAppointments {
        appointments: {
            id: string,
            startsAt: string,
            endsAt: string,
            reason: string
            client?: {
                id: string,
                firstName: string,
                lastName: string,
                patient: {
                    id: string,
                    name: string
                }
            },
            resourceId: string,
            resource?:{
                id: string,
                firstName: string,
                lastName: string
            },
            appointmentType?:{
                id: string,
                name: string
            },
            appointmentStatus?:{
                id: string,
                name: string
            }
        }[]
    }
    export interface GetResources{
        resources: {
            id: string,
            fullName: string
        }[]
    }
    export interface GetBlockoffs{
        blockoffs: {
            id: string,
            startsAt: string
            endsAt: string
            reason: string
            resourceId: string
            resource?: {
                id: string,
                fullName: string
            }
        }[]
    }
    export interface GetCompanies {
        companies: {
            id: string,
            name: string
        }[]
    }
    export interface GetPractices {
        practices: {
            id: string,
            name: string
        }[]
    }
    export interface GetSites {
        sites: {
            id: string,
            name: string
        }[]
    }
    export interface AddUserToSite {
        addUserToSite: {
            id: string,
            siteId: string,
            userId: string,
            read: boolean | null,
            update: boolean | null,
            addUser: boolean | null,
            removeUser: boolean | null,
            createdAt: Date,
            updatedAt: Date
        }
    }
    export interface AddUserToPractice {
        addUserToPractice: {
            id: string,
            siteId: string,
            userId: string,
            read: boolean | null,
            update: boolean | null,
            addUser: boolean | null,
            removeUser: boolean | null,
            createdAt: Date,
            updatedAt: Date
        }
    }
    export interface AddUserToCompany {
        addUserToCompany: {
            id: string,
            siteId: string,
            userId: string,
            read: boolean | null,
            update: boolean | null,
            addUser: boolean | null,
            removeUser: boolean | null,
            createdAt: Date,
            updatedAt: Date
        }
    }
    export interface GetInitialData {
        companies: {
            id: string
            name: string
            practices?: {
                id: string
                name: string
                sites?: {
                    id: string
                    name: string
                }[]
            }[]
        }[]
    }
}
const apiClient = {
    connection: new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    }),
    authenticate: (token: string)=>{
        return apiClient.connection.mutate({
            mutation: gql`
                mutation($token: String!){
                    authenticate(token: $token){
                        firstName
                        lastName
                        email
                        mobilePhone
                        token
                        isSuper
                    }
                }
            `,
            variables: {
                token
            }
        })
    },
    mutations: {
        CREATE_COMPANY: gql`
            mutation ($company: CompanyInput!){
                createCompany(company: $company){
                    id
                    name
                }
            }
        `,
        CREATE_PRACTICE: gql`
            mutation ($practice: PracticeInput!){
                createPractice(practice: $practice){
                    id
                    name
                }
            }
        `,
        CREATE_SITE: gql`
            mutation ($site: SiteInput!){
                createSite(site: $site){
                    id
                    name
                }
            }
        `,
        ADD_USER_TO_SITE: gql`
            mutation ($email: String!, $siteId: String!){
                addUserToSite(email: $email, siteId: $siteId){
                    id
                }
            }
        `,
        ADD_USER_TO_PRACTICE: gql`
            mutation ($email: String!, $practiceId: String!){
                addUserToPractice(email: $email, practiceId: $practiceId){
                    id
                }
            }
        `,
        ADD_USER_TO_COMPANY: gql`
            mutation ($email: String!, $companyId: String!){
                addUserToCompany(email: $email, companyId: $companyId){
                    id
                }
            }
        `
    },
    queries: {
        GET_COMPANIES: gql`
            query {
                companies {
                    id
                    name
                }
            }
        `,
        GET_PRACTICES: gql`
            query {
                practices {
                    id
                    name
                    companyId
                }
            }
        `,
        GET_SITES: gql`
            query {
                sites {
                    id
                    name
                    practiceId
                }
            }
        `,
        GET_APPOINTMENTS: gql`
            query ($siteId: String!, $startingAt: String!, $endingAt: String!){
                appointments(siteId: $siteId, startingAt: $startingAt, endingAt: $endingAt) {
                    id
                    startsAt
                    endsAt
                    reason
                    resourceId
                    client {
                        id
                        firstName
                        lastName
                        patients {
                            id
                            name
                        }
                    },
                    resource {
                        id
                        fullName
                    }
                    appointmentType{
                        id
                        name
                    }
                    appointmentStatus{
                        id
                        name
                    }
                }
            }
        `,
        GET_CALENDAR: gql`
            query ($siteId: String!, $startingAt: String!, $endingAt: String!, $resourceId: String){
                appointments(siteId: $siteId, startingAt: $startingAt, endingAt: $endingAt, resourceId: $resourceId) {
                    id
                    startsAt
                    endsAt
                    reason
                    resourceId
                    client {
                        id
                        firstName
                        lastName
                        patients {
                            id
                            name
                        }
                    }
                    appointmentStatus{
                        id
                        name
                    }
                    appointmentType{
                        id
                        name
                    }
                },
                blockoffs(siteId: $siteId, startingAt: $startingAt, endingAt: $endingAt, resourceId: $resourceId) {
                    id
                    startsAt
                    endsAt
                    resourceId
                    resource {
                        id
                        fullName
                    }
                }
            }
        `,
        GET_CURRENT_USER_DATA: gql`
            query {
                companies {
                    id
                    name
                    practices{
                        id
                        name
                        sites{
                            id
                            name
                        }
                    }
                }
            }
        `,
        GET_RESOURCES: gql`
            query ($siteId: String!){
                resources(siteId: $siteId){
                    id
                    fullName
                }
            }
        `,

    }
}

export default apiClient