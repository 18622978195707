import { Button, Link } from "@mui/material";
import { Navigate } from "react-router-dom";

const Authenticate = ()=>{
    const navigateToAuth = ()=>{
        const production = 'ddc5b7702085f08500340a733f19f422e8e6b8f49fac935b02'
        const local = '2879f78973217d749d2f01b041c1d327cf8815695d7700053a'
        window.location.href = `https://authentication.vetsuite.io?publicKey=${window.location.hostname.includes('localhost') ? local : production}`
    }
    return (
        <div>
            <Button variant='contained' onClick={navigateToAuth}>Authenticate</Button>
        </div>
    )
}
export default Authenticate;