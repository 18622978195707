import { Link } from "react-router-dom"
import useSession from "../helpers/useSessionState"
import Layout from "../components/Layout"
import { Calendar as RBCCalendar, Views, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { useQuery } from "@apollo/client"
import apiClient, { ApiClient } from "../lib/apiClient"
import { useState } from "react"
import randomColor from 'randomcolor';
import color from 'color';
import { Box, Drawer, MenuItem, Select, SelectChangeEvent } from "@mui/material"
const Appointment = 'Appointment'
const Blockoff = 'Blockoff'
const localizer = momentLocalizer(moment)
let resourceColors : {
    [key: string]: {
        color: string, 
        backgroundColor: string, 
        border: string
    }
} = {}

interface Event {
    startsAt: string
    endsAt: string
    reason: string,
    resourceId: string,
    type: string,
    resource?: {
        id: string,
        fullName: string
    }
    client?: {
        id: string,
        firstName: string,
        lastName: string
        patients: {
            id: string,
            name: string
        }[]
    }
    appointmentStatus?: {
        id: string
        name: string
    }
    appointmentType?: {
        id: string
        name: string
    }
}
interface State {
    currentDate: Date, 
    resource?: {
        id: string, fullName: string
    },
    currentEvent?: Event
}
const Calendar = ()=>{
    console.log("VERY BEGINNING")
    const [state, setState] = useState<State>({
        currentDate: moment().toDate()
    })
    const [sessionState, setSession] = useSession()

    
    const {data: companyData } = useQuery<ApiClient.GetCompanies>(apiClient.queries.GET_COMPANIES);
    const {data: practiceData } = useQuery<ApiClient.GetPractices>(apiClient.queries.GET_PRACTICES);
    const {data: siteData } = useQuery<ApiClient.GetSites>(apiClient.queries.GET_SITES);

    if(sessionState && !sessionState?.isSuper){
        const isLoaded = {
            company: sessionState?.currentCompanyId != undefined,
            practice: sessionState?.currentPracticeId != undefined,
            site: sessionState?.currentSiteId != undefined
        }; 

        if(sessionState?.currentCompanyId == undefined || sessionState?.currentPracticeId == undefined || sessionState?.currentSiteId == undefined){
            if(sessionState?.currentCompanyId == undefined && companyData?.companies?.[0]?.id){
                setSession({
                    ...sessionState,
                    currentCompanyId: companyData.companies[0].id
                })
                isLoaded.company = true
            }
            if(sessionState?.currentPracticeId == undefined && practiceData?.practices?.[0]?.id){
                setSession({
                    ...sessionState,
                    currentPracticeId: practiceData.practices[0].id
                })
                isLoaded.practice = true
            }
            
            if(sessionState?.currentSiteId == undefined && siteData?.sites?.[0]?.id){
                setSession({
                    ...sessionState,
                    currentSiteId: siteData.sites[0].id
                })
                isLoaded.site = true
            }

            if(isLoaded.company && isLoaded.practice && isLoaded.site){
                window.location.href = '/calendar'
            }
        }
    }


    const { loading, error, data } = useQuery<ApiClient.GetCalendar>(apiClient.queries.GET_CALENDAR, {
        variables:{
            siteId: sessionState?.currentSiteId ?? "",
            startingAt: moment(state.currentDate).startOf('week').startOf('day').startOf('hour').startOf('minute').startOf('second').utc().format(),
            endingAt: moment(state.currentDate).endOf('week').endOf('day').endOf('hour').endOf('minute').endOf('second').utc().format(),
            resourceId: state.resource?.id
        },
        pollInterval: 0
    });

    const { loading: resourceLoading, error: resourceError, data: resourceData } = useQuery<ApiClient.GetResources>(apiClient.queries.GET_RESOURCES, {
        variables:{
            siteId: sessionState?.currentSiteId,
            startingAt: moment(state.currentDate).startOf('week').startOf('day').startOf('hour').startOf('minute').startOf('second').utc().format(),
            endingAt: moment(state.currentDate).endOf('week').endOf('day').endOf('hour').endOf('minute').endOf('second').utc().format()
        },
        pollInterval: 0
    });
    
    const events = [
        ...(
            data?.appointments ?? []
        ).map((appointment)=>({
            ...appointment,
            startsAt: moment.parseZone(appointment.startsAt).toDate(),
            endsAt: moment.parseZone(appointment.endsAt).toDate(),
            resourceId: appointment.resourceId,
            type: Appointment
        })),
        ...(data?.blockoffs ?? []).filter((blockoff)=>
            blockoff.startsAt && blockoff.endsAt
        ).map((blockoff)=>({
            startsAt: moment.parseZone(blockoff.startsAt).toDate(),
            endsAt: moment.parseZone(blockoff.endsAt).toDate(),
            reason: blockoff.reason ?? '',
            resourceId: blockoff.resourceId,
            client: null,
            type: Blockoff
        }))
    ]


    const handleNavigate = (date: any)=>{
        setState({
            currentDate: date,
            resource: state.resource
        })
    }

    const handleProviderChange = (resourceId: SelectChangeEvent<string>)=>{
        const resource = resourceData?.resources.find((resource)=>resource.id==resourceId.target.value)
        setState({
            currentDate: state.currentDate,
            resource
        })
    }
    const handleShowEvent = (event: Event)=>{
        setState({
            ...state,
            currentEvent: event
        })
    }
    const closeDrawer = ()=>{
        setState({
            ...state,
            currentEvent: undefined
        })
    }
    return (
        <Layout page="Calendar">
                <Drawer
                    style={{zIndex: 1205}}
                    anchor={'right'}
                    open={!!state.currentEvent}
                    onClose={closeDrawer}
                    
                >
                    <Box sx={{ m: 2 }}>
                        Date: {moment(state.currentEvent?.startsAt).format('M/DD/YY')}
                        <br />
                        Starts At: {moment(state.currentEvent?.startsAt).format('h:mm a')}
                        <br />
                        {moment(state.currentEvent?.startsAt).format('M/DD/YY') != moment(state.currentEvent?.endsAt).format('M/DD/YY') && (
                            <>
                                {moment(state.currentEvent?.endsAt).format('M/DD/YY')}
                                <br />
                            </>
                        )}
                        Ends At: {moment(state.currentEvent?.endsAt).format('h:mm a')}
                        {state.currentEvent?.type==Appointment && (
                            <>
                                <br />
                                Client: {state.currentEvent?.client?.firstName} {state.currentEvent?.client?.lastName}
                                <br />
                                Patient: {state.currentEvent?.client?.patients?.[0]?.name}
                            </>
                        )}
                        {state.currentEvent?.type == Appointment && (
                            <>
                                <br />
                                Appointment Type: {state.currentEvent?.appointmentType?.name}
                                <br />
                                Appointment Status: {state.currentEvent?.appointmentStatus?.name}
                            </>
                        )}
                        
                        <br />
                        {state.currentEvent?.type}
                    </Box>
                </Drawer>
                <div style={{top: 0, bottom: 0, left: 0, right: 0, position: 'absolute', display: loading ? 'block' : 'none', zIndex: 10000, backgroundColor: 'rgba(255,255,255, .5)', paddingTop: '25%'}}>
                    Loading ...
                </div>
                <div>
                    <Select defaultValue='all' size="small" onChange={handleProviderChange}>
                        <MenuItem value='all'>All Providers</MenuItem>
                        {resourceData?.resources?.map((resource, i)=>(
                            <MenuItem value={resource.id} key={i}>{resource.fullName}</MenuItem>
                        ))}
                    </Select>
                    <br />
                    <br />
                </div>
                <RBCCalendar
                    defaultView={Views.WEEK}
                    localizer={localizer}
                    events={events as any ?? []}
                    startAccessor="startsAt"
                    endAccessor="endsAt"
                    onNavigate={handleNavigate}
                    titleAccessor="reason"
                    onSelectEvent={handleShowEvent}
                    eventPropGetter={(event) => {
                        let style = {backgroundColor: '#7e7e7e', border: '1px solid #2e2e2e'}
                        if(resourceColors[event.resourceId]){
                            style = resourceColors[event.resourceId]
                        }else{
                            const c = randomColor()
                            const isDark = color(c).isDark()
                            resourceColors[event.resourceId] = {
                                backgroundColor: c,
                                border: `1px solid ${color(c).darken(.9).toString()}`,
                                color: isDark ? '#fff' : '#000',
                            }
                        }
                        
                        return event?.client?.id ? {style} : { style: { backgroundColor: '#333', border: '1px solid #111'} }
                    }}
                    style={{ height: 'calc(100vh - 300px)' }}
                />
        </Layout>
    )
}
export default Calendar