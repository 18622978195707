import React from 'react';
import './App.css';
import Authenticate from './Authenticate';
import apiClient, { ApiClient } from './lib/apiClient';
import { ApolloProvider, useQuery } from '@apollo/client';
import { RouterProvider } from 'react-router-dom';
import router from './Router';
import useSession from './helpers/useSessionState';


let params = new URLSearchParams(document.location.search);

function App() {
    const [sessionState, setSessionState] = useSession()

    let token : string | null = ''

    if(window.location.pathname === '/session/authentication'){
        token = params.get('token')
        if(token){
            if(!sessionState?.email){
                apiClient.authenticate(token).then((response)=>{
                    setSessionState(
                        response.data.authenticate
                    )
                })
            }else{
                window.location.href='/'
            }
        }
    }
    
    return (
        <ApolloProvider client={apiClient.connection}>
            <div className="App">
                {!sessionState?.email ? (
                    <Authenticate />
                ):(
                    <RouterProvider router={router} />
                )}
            </div>
        </ApolloProvider>
    );
}

export default App;
