import {
    createBrowserRouter,
} from "react-router-dom";
import Companies from "./pages/Companies";
import Practices from "./pages/Practices";
import Sites from "./pages/Sites";
import Home from "./pages/Home";
import Calendar from "./pages/Calendar";

const router = createBrowserRouter([
    {
      path: "/companies",
      Component: Companies,
    },
    {
      path: "/practices",
      Component: Practices,
    },
    {
      path: "/sites",
      Component: Sites,
    },
    {
      path: "/calendar",
      Component: Calendar,
    },
    {
      path: "/about",
      element: <div>About</div>,
    },
    {
        path: "*",
        Component: Home
    }
]);


export default router