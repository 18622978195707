import useLocalStorage from "../lib/useLocalStorage"

interface User {
    firstName?: string,
    lastName?: string,
    email?: string,
    mobilePhone?: string,
    token: string,
    isSuper: Boolean,
    currentCompanyId?: string
    currentPracticeId?: string
    currentSiteId?: string
}

const useSession = ()=>{
    return useLocalStorage<User>('session', {})
}

export default useSession
