import { Link, useNavigate } from "react-router-dom"
import useSession from "../../helpers/useSessionState"
import { Divider, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import DashboardIcon from '@mui/icons-material/Dashboard';

import GroupsIcon from '@mui/icons-material/Groups';
import PeopleIcon from '@mui/icons-material/People';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import LayersIcon from '@mui/icons-material/Layers';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import PetsIcon from '@mui/icons-material/Pets';

const MenuBar = ()=>{
    const navigate = useNavigate()
    
    const [sessionState] = useSession()
    // <Link to='/practices'>Practices</Link>
    // <Link to='/sites'>Sites</Link>
    // <Link to='/calendar'>Calendar</Link>
    const navigateTo = (target: string)=>(
        navigate(target)
    )
    return (
        <>
        <ListItemButton onClick={navigateTo.bind(null, '/')}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
        {sessionState?.isSuper && (
            <ListItemButton onClick={navigateTo.bind(null, '/companies')}>
                <ListItemIcon>
                    <BusinessOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Companies" />
            </ListItemButton>
        )}
        <ListItemButton onClick={navigateTo.bind(null, '/practices')}>
          <ListItemIcon>
            <GroupsIcon />
          </ListItemIcon>
          <ListItemText primary="Practices" />
        </ListItemButton>
        <ListItemButton onClick={navigateTo.bind(null, '/sites')}>
          <ListItemIcon>
            <StoreOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Sites" />
        </ListItemButton>
        <ListItemButton>
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary="Integrations" />
        </ListItemButton>
        <Divider sx={{ my: 1 }} />
        <ListItemButton onClick={navigateTo.bind(null, '/calendar')}>
          <ListItemIcon>
            <CalendarMonthOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Calendar" />
        </ListItemButton>
        <ListItemButton onClick={navigateTo.bind(null, '/clients')}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Pet Parents" />
        </ListItemButton>
        <ListItemButton onClick={navigateTo.bind(null, '/patients')}>
          <ListItemIcon>
            <PetsIcon />
          </ListItemIcon>
          <ListItemText primary="Pets" />
        </ListItemButton>
      </>
    )
}

export default MenuBar