import { useMutation } from "@apollo/client";
import { Box, Button, FormControl, Hidden, Input, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { useForm } from "react-hook-form"
import apiClient, { ApiClient } from "../../lib/apiClient";
import useSession from "../../helpers/useSessionState";
import moment from "moment-timezone";
import { useState } from "react";

interface CompanyFormInputs {
    email: string
}

interface CompanyCreateProps {
    onComplete?: Function
}
const AddUserToCompany = (props: CompanyCreateProps)=>{
    const [state, setState] = useState<{integrationName?: string}>({})
     
    const [sessionState] = useSession()
    const {
        register,
        handleSubmit,
        // formState: { errors },
        getValues,
        setValue
    } = useForm<CompanyFormInputs>()
    const [addUserToCompany, { loading, error }] = useMutation<ApiClient.AddUserToCompany["addUserToCompany"]>(apiClient.mutations.ADD_USER_TO_COMPANY);

    const handleAddUserCompany = (params: any)=>{
        addUserToCompany({
            variables: {
                email: params.email,
                companyId: sessionState?.currentCompanyId,
            }
        }).then(()=>{
            if(props.onComplete) props.onComplete()
        }).catch((error)=>{
            console.log(error)
        })
    }

    const handleIntegration = (key: string, e: React.ChangeEvent<HTMLInputElement>)=> {
        const integration = JSON.parse(getValues('email') ?? '{}')
        integration[key] = e.target.value
        setValue('email', JSON.stringify(integration))
    }
    const handleIntegrationChange = (e: SelectChangeEvent)=>{
        setState({
            integrationName: String(e.target.value)
        })
    }
    return (
        <Box
            sx={{p: 2}}
        >
            <form onSubmit={handleSubmit(handleAddUserCompany)}>
                {/* register your input into the hook by invoking the "register" function */}
                {loading ?(<>
                    Loading...
                </>):(
                    <>
                        {error && error?.message}
                        <FormControl>
                            <Typography component="h4">
                                Add User To Company
                            </Typography>
                        </FormControl>
                        <br />
                        <br />
                        <FormControl>
                            <InputLabel htmlFor="email">Email</InputLabel>
                            <Input id="email" defaultValue="" {...register("email")} required={true} />
                        </FormControl>
                        <br />
                        <br />
                        
                        {/* include validation with required or other standard HTML validation rules */}
                        {/* errors will return when field validation fails  */}
                        <br />
                        <br />
                        <Button type="submit">Create</Button>
                    </>
                )}
            </form>
        </Box>
    )
}

export default AddUserToCompany